<ng-container *loading="allRequests$ | async; mask: maskTmpl">
  <ul class="flex flex-col gap-2 mt-2">
    <li>
      <app-multi-select-address
        [onlyProperty]="onlyProperty()"
        [queries]="queries"
        [cities$]="cities$()"
      ></app-multi-select-address>
    </li>
    <li class="flex flex-wrap gap-y-1">
      <div class="list mr-2">
        <p>{{ '房型' | translate }}：</p>
        @if (shapes$ | async) {
          <div>
            <ng-container formArrayName="shapes">
              @for (shape of shapes.controls; track shape; let i = $index) {
                <ng-container [formGroupName]="i">
                  <span
                    (click)="checkElm.click()"
                    [ngClass]="{
                      'bg-primary text-white': shape.get('checked').value
                    }"
                  >
                    {{ shape.get('name').value | translate }}
                    <input
                      class="hidden"
                      #checkElm
                      type="checkbox"
                      formControlName="checked"
                    />
                  </span>
                </ng-container>
              }
            </ng-container>
          </div>
        }
      </div>
      <div class="list mr-2">
        <p>{{ '類型' | translate }}：</p>
        @if (kinds$ | async) {
          <div>
            <ng-container formArrayName="kinds">
              @for (kind of kinds.controls; track kind; let i = $index) {
                <ng-container [formGroupName]="i">
                  <span
                    (click)="checkElm.click()"
                    [ngClass]="{
                      'bg-primary text-white': kind.get('checked').value
                    }"
                  >
                    {{ kind.get('name').value }}
                    <input
                      class="hidden"
                      #checkElm
                      type="checkbox"
                      formControlName="checked"
                    />
                  </span>
                </ng-container>
              }
            </ng-container>
          </div>
        }
      </div>
      <div class="list">
        <p>{{ '用途' | translate }}：</p>
        @if (usages$ | async) {
          <div>
            <ng-container formArrayName="usages">
              @for (usage of usages.controls; track usage; let i = $index) {
                <ng-container [formGroupName]="i">
                  <span
                    (click)="checkElm.click()"
                    [ngClass]="{
                      'bg-primary text-white': usage.get('checked').value
                    }"
                  >
                    {{ usage.get('name').value }}
                    <input
                      class="hidden"
                      #checkElm
                      type="checkbox"
                      formControlName="checked"
                    />
                  </span>
                </ng-container>
              }
            </ng-container>
          </div>
        }
      </div>
    </li>
    <li class="list">
      <p>{{ (isRent ? '租金' : '售金') | translate }}：</p>
      <div class="flex flex-wrap">
        <ng-container formArrayName="prices">
          @for (price of prices.controls; track $index; let i = $index) {
            <ng-container [formGroupName]="i">
              <span
                (click)="checkElm.click()"
                [ngClass]="{
                  'bg-primary text-white': price.get('checked').value
                }"
              >
                {{ price.get('name').value }}
                <input
                  class="hidden"
                  #checkElm
                  type="checkbox"
                  formControlName="checked"
                />
              </span>
            </ng-container>
          }
        </ng-container>
        <div class="flex gap-1 items-center">
          <input
            type="number"
            class="rounded border border-gray-300 w-20 h-[30px] p-1"
            formControlName="priceFrom"
          />
          <div>-</div>
          <input
            type="number"
            class="rounded border border-gray-300 w-20 h-[30px] p-1"
            formControlName="priceTo"
          />
          <div>{{ '元' | translate }}</div>
        </div>
      </div>
    </li>
    <li class="list">
      <p>{{ '格局' | translate }}：</p>
      <div>
        <ng-container formArrayName="patterns">
          @for (pattern of patterns.controls; track pattern; let i = $index) {
            <ng-container [formGroupName]="i">
              <span
                (click)="checkElm.click()"
                [ngClass]="{
                  'bg-primary text-white': pattern.get('checked').value
                }"
              >
                {{ pattern.get('name').value | translate }}
                <input
                  class="hidden"
                  #checkElm
                  type="checkbox"
                  formControlName="checked"
                />
              </span>
            </ng-container>
          }
        </ng-container>
      </div>
    </li>
    @if (lifeFunctions$ | async) {
      <li class="list">
        <p>{{ '格局特點' | translate }}：</p>
        <div>
          <ng-container formArrayName="patternFeatures">
            @for (
              patternFeature of patternFeatures.controls;
              track patternFeature;
              let i = $index
            ) {
              <ng-container [formGroupName]="i">
                <span
                  (click)="checkElm.click()"
                  [ngClass]="{
                    'bg-primary text-white': patternFeature.get('checked').value
                  }"
                >
                  {{ patternFeature.get('name').value }}
                  <input
                    class="hidden"
                    #checkElm
                    type="checkbox"
                    formControlName="checked"
                  />
                </span>
              </ng-container>
            }
          </ng-container>
        </div>
      </li>
    }
    <li class="list">
      <p>{{ '風格' | translate }}：</p>
      <div>
        <ng-container formArrayName="styles">
          @for (style of styles.controls; track style; let i = $index) {
            <ng-container [formGroupName]="i">
              <span
                (click)="checkElm.click()"
                [ngClass]="{
                  'bg-primary text-white': style.get('checked').value
                }"
              >
                {{ style.get('name').value }}
                <input
                  class="hidden"
                  #checkElm
                  type="checkbox"
                  formControlName="checked"
                />
              </span>
            </ng-container>
          }
        </ng-container>
      </div>
    </li>
  </ul>
  <!-- https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css -->
  <input type="checkbox" class="peer hidden" [checked]="expanded()" />
  <!-- TODO: should calculate height manually, otherwise that may meet screen size issue -->
  <div
    class="max-h-0 overflow-hidden transition-all duration-1000 md:duration-300 peer-checked:max-h-[200vh] md:peer-checked:max-h-screen"
  >
    <div class="collapsible">
      <ul class="flex flex-col gap-2">
        <li class="list mt-2">
          <p>{{ '屋齡' | translate }}：</p>
          <div>
            <ng-container formArrayName="ages">
              @for (age of ages.controls; track age; let i = $index) {
                <ng-container [formGroupName]="i">
                  <span
                    (click)="checkElm.click()"
                    [ngClass]="{
                      'bg-primary text-white': age.get('checked').value
                    }"
                  >
                    {{ age.get('name').value | translate }}
                    <input
                      class="hidden"
                      #checkElm
                      type="checkbox"
                      formControlName="checked"
                    />
                  </span>
                </ng-container>
              }
            </ng-container>
          </div>
        </li>
        <li class="list">
          <p>{{ '樓層' | translate }}：</p>
          <div>
            <ng-container formArrayName="floors">
              @for (floor of floors.controls; track floor; let i = $index) {
                <ng-container [formGroupName]="i">
                  <span
                    (click)="checkElm.click()"
                    [ngClass]="{
                      'bg-primary text-white': floor.get('checked').value
                    }"
                  >
                    {{ floor.get('name').value | translate }}
                    <input
                      class="hidden"
                      #checkElm
                      type="checkbox"
                      formControlName="checked"
                    />
                  </span>
                </ng-container>
              }
            </ng-container>
          </div>
        </li>
        <li class="list">
          <p>
            {{ '面積' | translate }}：
            <br />
            <span class="text-xs text-secondary"
              >[{{ '權狀坪數' | translate }}]</span
            >
          </p>
          <div>
            <ng-container formArrayName="areas">
              @for (area of areas.controls; track area; let i = $index) {
                <ng-container [formGroupName]="i">
                  <span
                    (click)="checkElm.click()"
                    [ngClass]="{
                      'bg-primary text-white': area.get('checked').value
                    }"
                  >
                    {{ area.get('name').value | translate }}
                    <input
                      class="hidden"
                      #checkElm
                      type="checkbox"
                      formControlName="checked"
                    />
                  </span>
                </ng-container>
              }
            </ng-container>
          </div>
        </li>
        <li class="list">
          <p>{{ '公設' | translate }}：</p>
          @if (publicUtilities$ | async) {
            <div>
              <ng-container formArrayName="publicUtilities">
                @for (
                  publicUtility of publicUtilities.controls;
                  track publicUtility;
                  let i = $index
                ) {
                  <ng-container [formGroupName]="i">
                    <span
                      (click)="checkElm.click()"
                      [ngClass]="{
                        'bg-primary text-white':
                          publicUtility.get('checked').value
                      }"
                    >
                      {{ publicUtility.get('name').value }}
                      <input
                        class="hidden"
                        #checkElm
                        type="checkbox"
                        formControlName="checked"
                      />
                    </span>
                  </ng-container>
                }
              </ng-container>
            </div>
          }
        </li>
        <li class="list">
          <p>{{ '環境' | translate }}：</p>
          <div>
            <ng-container formArrayName="lifeFunctions">
              @for (
                lifeFunction of lifeFunctions.controls;
                track lifeFunction;
                let i = $index
              ) {
                <ng-container [formGroupName]="i">
                  <span
                    (click)="checkElm.click()"
                    [ngClass]="{
                      'bg-primary text-white': lifeFunction.get('checked').value
                    }"
                  >
                    {{ lifeFunction.get('name').value }}
                    <input
                      class="hidden"
                      #checkElm
                      type="checkbox"
                      formControlName="checked"
                    />
                  </span>
                </ng-container>
              }
            </ng-container>
          </div>
        </li>
        <li class="list">
          <p>{{ '更多' | translate }}：</p>
          <div class="flex-col">
            <header class="flex gap-1 flex-wrap">
              <app-my-select-option
                value="parkingTypes"
                [(ngModel)]="moreActive"
                [ngModelOptions]="{ standalone: true }"
              >
                {{ '車位' | translate }}
              </app-my-select-option>
              <app-my-select-option
                value="devices"
                [(ngModel)]="moreActive"
                [ngModelOptions]="{ standalone: true }"
              >
                {{ '提供設備' | translate }}
              </app-my-select-option>
              <app-my-select-option
                value="furnitures"
                [(ngModel)]="moreActive"
                [ngModelOptions]="{ standalone: true }"
              >
                {{ '傢俱' | translate }}
              </app-my-select-option>
              <app-my-select-option
                value="faces"
                [(ngModel)]="moreActive"
                [ngModelOptions]="{ standalone: true }"
              >
                {{ '朝向' | translate }}
              </app-my-select-option>
              <app-my-select-option
                value="pet"
                [(ngModel)]="moreActive"
                [ngModelOptions]="{ standalone: true }"
              >
                {{ '寵物' | translate }}
              </app-my-select-option>
              <app-my-select-option
                value="shortest"
                [(ngModel)]="moreActive"
                [ngModelOptions]="{ standalone: true }"
              >
                {{ '短期租賃' | translate }}
              </app-my-select-option>
              <app-my-select-option
                value="smoke"
                [(ngModel)]="moreActive"
                [ngModelOptions]="{ standalone: true }"
              >
                {{ '抽菸' | translate }}
              </app-my-select-option>
            </header>
            <main class="pt-2">
              @switch (moreActive) {
                @case ('parkingTypes') {
                  <div class="list">
                    <div>
                      <ng-container formArrayName="parkingTypes">
                        @for (
                          item of parkingTypes.controls;
                          track item;
                          let i = $index
                        ) {
                          <ng-container [formGroupName]="i">
                            <span
                              (click)="checkElm.click()"
                              [ngClass]="{
                                'bg-primary text-white':
                                  item.get('checked').value
                              }"
                            >
                              {{ item.get('name').value | translate }}
                              <input
                                class="hidden"
                                #checkElm
                                type="checkbox"
                                formControlName="checked"
                              />
                            </span>
                          </ng-container>
                        }
                      </ng-container>
                    </div>
                  </div>
                }
                @case ('devices') {
                  <div class="list">
                    @if (devices$ | async) {
                      <div>
                        <ng-container formArrayName="devices">
                          @for (
                            item of devices.controls;
                            track item;
                            let i = $index
                          ) {
                            <ng-container [formGroupName]="i">
                              <span
                                (click)="checkElm.click()"
                                [ngClass]="{
                                  'bg-primary text-white':
                                    item.get('checked').value
                                }"
                              >
                                {{ item.get('name').value }}
                                <input
                                  class="hidden"
                                  #checkElm
                                  type="checkbox"
                                  formControlName="checked"
                                />
                              </span>
                            </ng-container>
                          }
                        </ng-container>
                      </div>
                    }
                  </div>
                }
                @case ('furnitures') {
                  <div class="list">
                    @if (furnitures$ | async) {
                      <div>
                        <ng-container formArrayName="furnitures">
                          @for (
                            item of furnitures.controls;
                            track item;
                            let i = $index
                          ) {
                            <ng-container [formGroupName]="i">
                              <span
                                (click)="checkElm.click()"
                                [ngClass]="{
                                  'bg-primary text-white':
                                    item.get('checked').value
                                }"
                              >
                                {{ item.get('name').value }}
                                <input
                                  class="hidden"
                                  #checkElm
                                  type="checkbox"
                                  formControlName="checked"
                                />
                              </span>
                            </ng-container>
                          }
                        </ng-container>
                      </div>
                    }
                  </div>
                }
                @case ('faces') {
                  <div class="list">
                    @if (faces$ | async) {
                      <div>
                        <ng-container formArrayName="faces">
                          @for (
                            item of faces.controls;
                            track item;
                            let i = $index
                          ) {
                            <ng-container [formGroupName]="i">
                              <span
                                (click)="checkElm.click()"
                                [ngClass]="{
                                  'bg-primary text-white':
                                    item.get('checked').value
                                }"
                              >
                                {{ item.get('name').value }}
                                <input
                                  class="hidden"
                                  #checkElm
                                  type="checkbox"
                                  formControlName="checked"
                                />
                              </span>
                            </ng-container>
                          }
                        </ng-container>
                      </div>
                    }
                  </div>
                }
                @case ('pet') {
                  <div class="list">
                    <div>
                      <ng-container formArrayName="pet">
                        @for (
                          item of pet.controls;
                          track item;
                          let i = $index
                        ) {
                          <ng-container [formGroupName]="i">
                            <span
                              (click)="checkElm.click()"
                              [ngClass]="{
                                'bg-primary text-white':
                                  item.get('checked').value
                              }"
                            >
                              {{ item.get('name').value | translate }}
                              <input
                                class="hidden"
                                #checkElm
                                type="checkbox"
                                formControlName="checked"
                              />
                            </span>
                          </ng-container>
                        }
                      </ng-container>
                    </div>
                  </div>
                }
                @case ('shortest') {
                  <div class="list">
                    <div>
                      <ng-container formArrayName="shortest">
                        @for (
                          item of shortest.controls;
                          track item;
                          let i = $index
                        ) {
                          <ng-container [formGroupName]="i">
                            <span
                              (click)="checkElm.click()"
                              [ngClass]="{
                                'bg-primary text-white':
                                  item.get('checked').value
                              }"
                            >
                              {{ item.get('name').value | translate }}
                              <input
                                class="hidden"
                                #checkElm
                                type="checkbox"
                                formControlName="checked"
                              />
                            </span>
                          </ng-container>
                        }
                      </ng-container>
                    </div>
                  </div>
                }
                @case ('smoke') {
                  <div class="list">
                    <div>
                      <ng-container formArrayName="smoke">
                        @for (
                          item of smoke.controls;
                          track item;
                          let i = $index
                        ) {
                          <ng-container [formGroupName]="i">
                            <span
                              (click)="checkElm.click()"
                              [ngClass]="{
                                'bg-primary text-white':
                                  item.get('checked').value
                              }"
                            >
                              {{ item.get('name').value | translate }}
                              <input
                                class="hidden"
                                #checkElm
                                type="checkbox"
                                formControlName="checked"
                              />
                            </span>
                          </ng-container>
                        }
                      </ng-container>
                    </div>
                  </div>
                }
              }
            </main>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-container>

<ng-template #maskTmpl>
  <ngx-skeleton-item
    [items]="loadingSkeletons"
    role="status"
    class="block mt-4 space-y-2.5 animate-pulse"
  ></ngx-skeleton-item>
  <span class="sr-only">Loading...</span>
</ng-template>
